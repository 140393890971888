.formErrors,
.formErrors-login {
  @extend .uk-alert;
  @extend .uk-alert-danger;

  button{
    @extend .uk-close;
    min-height: 20px;
  }

  p {
    font-weight: bold;
  }
}

.formErrors {
  @extend .uk-alert;
  @extend .uk-margin-top;
}
