.main-content {
  @extend .uk-container;
  @extend .uk-margin-large-bottom;
  box-sizing: border-box;
  max-width: 980px;
  padding: 0 25px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1220px) {
    max-width: 1200px;
    padding: 0 35px;
  }
}

dbq-inline-questions,
dbq-guided-essay {
  display: block;
}
