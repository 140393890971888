.lang-switcher,
.dbq-switcher {
  li {
    display: none;

    &[active],
    &.uk-active {
      display: inline-block;
    }
  }
}

.lang-switcher {
  margin-bottom: 0px;
  padding-left: 0px;
}

// ODD: these styles were in the ui core
.uk-switcher.pa-button,
.lang-switcher.pa-button {
  display: inline-block;

  &.lang-switcher {
    margin: 0px;

    .uk-button.langurl {
      margin: 4px;
    }

    @media (min-width: $breakpoint-medium) {
      width: 100px;

      .uk-button.langurl {
        width: 85px;
      }
    }
  }
}
