/* fonts.scss */

body {
  letter-spacing: 0.028 rem;
}

/* FontAwesome */
@font-face {
  font-family: 'FontAwesome';
  font-display: fallback;
  src: url('../fonts/fontawesome.eot');
  src: url('../fonts/fontawesome.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fontawesome.woff2') format('woff2');
}

.uk-navbar-toggle:after {
  content: "f";
}
.uk-icon-eye-slash:before {
    content: "j";
}
.uk-icon-eye:before {
    content: "i";
}
.uk-icon-folder-open:before {
    content: "k";
}
.uk-icon-cog:before {
    content: "h";
}
.uk-icon-caret-down:before,
.uk-icon-sort-down:before {
    content: "g";
}
.uk-icon-times:before {
  content: "c";
}

/* font awesome */
.fontawesome {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fontawesome-chevron-right:before {
  content: "\61";
}
.fontawesome-chevron-left:before {
  content: "\62";
}
.fontawesome-times:before {
  content: "\63";
}
.fontawesome-download:before {
  content: "\64";
}
.fontawesome-sign-out:before {
  content: "\65";
}
.fontawesome-bars:before {
  content: "\66";
}
.fontawesome-caret-down:before {
  content: "\67";
}
.fontawesome-cog:before {
  content: "\68";
}
.fontawesome-eye:before {
  content: "\69";
}
.fontawesome-eye-slash:before {
  content: "\6a";
}
.fontawesome-folder-open:before {
  content: "\6b";
}

.uk-icon-pencil:before,
.fontawesome-pencil:before {
  content: "\6c";
}
.uk-icon-trash-o:before,
.fontawesome-trash-o:before {
  content: "\6d";
}
.uk-icon-arrows-v:before,
.fontawesome-arrows-v:before {
  content: "\6e";
}

.serif,
.StuSide,
.StuSide h1,
.StuSide h2,
.StuSide h3,
.StuSide h4,
.StuSide h5,
.StuSide h6  { font-family: Times, 'Times New Roman', Georgia, serif !important; }

.teachSide,
.sans-serif { font-family: Helvetica, 'Helvetica Neue', Arial, sans-serif !important; }
