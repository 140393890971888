button {
  @extend .uk-button;

  &[type="submit"] {
    @extend .uk-button-primary;
    @extend .uk-margin-top;
  }
}

.teachReviewEssayBtnOff,
.teachReviewEssayBtn,
.essayFeedbackBtn  {
  @extend .uk-button;
  @extend .uk-button-primary;
}
.teachReviewEssayBtnOff,
.teachReviewEssayBtn {
  @extend .uk-margin-small-bottom;
}

.teachReviewEssayBtn,
.essayFeedbackBtn  {
  background-color: $annoEditNav;
}

.essayFeedbackBtn {
  white-space: nowrap;
}

.essay-indicator {
  color: $annoEditNav;
}

.uk-button-orange {
  background-color: $DashBOrange;
  color: $White;

  &:hover {
    background-color: lighten($DashBOrange, 5%);
    color: $White;
  }
}

.uk-button-success {
  background-color: $DashBGreen;
  color: $White;

  &:hover {
    background-color: lighten($DashBGreen, 5%);
    color: $White;
  }
}
