dbq-inst-dash {
  position: relative;
}

dbq-assgn-templates-grid,
dbq-essaybuilder-grid,
dbq-district-templates-grid,
dbq-roster-grid,
dbq-teacher-grid,
dbq-student-grid,
dbq-custom-comment-grid,
dbq-table-grid,
dbq-district-rubrics-grid,
dbq-shared-imported-grid,
dbq-shared-by-me-grid,
dbq-rubrics-shared-imported-grid,
dbq-rubrics-shared-by-me-grid {

  // doubly hide buttons
  &[hidden]{
    cursor: default;
    pointer-events: none;

    button,
    a {
      cursor: default;
      pointer-events: none;
    }
  }

  // display: contents;
  > .transformWrapper {
    display: grid;
    grid-auto-flow: row;
    grid-column-gap: 0;
    grid-row-gap: 0;
    opacity: 1;
    transition-property: opacity, transform;
    transition-duration: .4s;
    transition-timing-function: ease-out;
    position: absolute;
    top: 0;
    transform-style: preserve-3d;
    transform: scaleX(1);
    transform-origin: 0 0;
    background-color: white;
    width: 100%;

    @media only screen and (max-width: 959px){
      overflow-x: auto;
      overflow-y: hidden;
    }

    [hidden]{
      display: none;
    }

    .uk-badge {
      height: fit-content;
      width: fit-content;
    }

    > header {
      background: $ActiveDDNav;
      box-sizing: border-box;
      height: 36px;
      align-content: center;
      display: grid;
      grid-auto-flow: row;
      font-size: 11px;

      > div {
        text-align: left;
        padding-left: 10px;
        box-sizing: border-box;

        @media only screen and (max-width: 959px){
          white-space: nowrap;
        }
      }
    }

    [fake] {
      color: $Disabled !important;
      background-color: $Disabled;
      border-bottom: 1px solid $Disabled;
      min-height: 80px;
      min-width: 100%;

      .uk-badge {
        min-height: auto;
        min-width: auto;
        color: $Disabled !important;
        background-color: $Disabled;
      }

      button,
      a {
        display: none;
      }
    }
  }

  &[slide-in] > .transformWrapper {
    transform-origin: 100% 0;
  }

  &[should-fade] > .transformWrapper {
    opacity: 0;
  }

  &[hidden] > .transformWrapper {
    display: grid;
    transform: scaleX(0);
  }

  &[hidden] {
    display: contents !important;
  }

  .no-assignments {
    grid-column: 1/7;
    margin-bottom: 40px;

    .no-assignments-text {
      padding: 18px 10px;
      border-bottom: 1px solid $ActiveDDNav;
      margin-bottom: 40px;
    }
  }
}

dbq-table-grid,
dbq-shared-imported-grid,
dbq-shared-by-me-grid,
dbq-rubrics-imported-grid,
dbq-rubrics-shared-by-me-grid {
  > .transformWrapper {
    > div {
      height: 100%;

      &[title="Actions"] {
        padding-left: 10px;
        justify-content: left;
        text-align: left;

        a {
          margin-right: 4px;
        }

        dbq-dropdown {
          display: inline;
          flex-wrap: inherit;
          align-self: auto;
          margin-left: 0;
          height: unset;

          .dropTitle {
            text-align: center;

            text-align: center;
            padding: 0px $button-padding-horizontal;
            line-height: $button-line-height;
            display: inline-block;
            height: auto;

            i {
              right: 8px;
            }

            svg.icon {
              height: 16px;
              min-height: 16px;
              width: 16px;
              min-width: 16px;
              margin-right: unset;
              margin-top: unset;
            }
          }

          .dropMenu {
            top: 30px;
            left: unset;
            right: 4px;
          }

          &[active] .dropMenu {
            overflow-y: inherit;
          }
        }
      }
    }
  }
}

dbq-table-grid {
  > .transformWrapper {
    grid-template-columns: minmax(200px, 8fr) repeat(3, 2fr) 5fr 3fr;
    // grid-auto-rows: 1fr;

    > header {
      grid-column-start: 1;
      grid-column-end: 7;
      grid-template-columns: minmax(200px, 8fr) repeat(3, 2fr) 5fr 3fr;
    }

    > div {
      align-self: center;
      text-align: left;
      max-height: 300px;
      // grid-auto-rows: 1fr;
      min-height: 48px;
      opacity: 1;
      // overflow: hidden;
      transition-property: opacity, max-height, border, paddin ;
      transition-duration: .4s;
      transition-timing-function: ease-out;
      border-bottom: 1px solid $ActiveDDNav;
      display: flex;
      align-items: center;
      justify-content: left;
      padding: 4px 10px;
      box-sizing: border-box;

      &.manage-directions {
        justify-content: left;
        text-align: left;
        grid-column: 1/7;
        padding: 20px 10px;
        border-bottom: 1px solid $GreyLite;
        margin-bottom: 1em;

        p {
          margin: 0;
          font-size: 12px;
          line-height: 18px;
        }
      }

      &.title {
        padding-left: 10px;
        justify-content: left;
        text-align: left;

        &.sample-essay {
          padding-left: 40px;
        }
      }

      &[title="Actions"] {
        dbq-dropdown {
          .dropTitle {
            height: 100%;
          }
        }
      }

      &[slide-up] {
        max-height: 0;
        min-height: 0;
        border-bottom: 0;
        padding: 0;
        overflow: hidden;
      }
    }
    a{
      max-width: 500px;
      transform: scaleX(1);
      transition-property: max-width, padding;
      transition-duration: .4s;
      transition-timing-function: ease-out;

      &[slide-out] {
        max-width: 0;
        padding: 0;
      }
    }
  }
}

dbq-roster-grid,
dbq-teacher-grid {
  > .transformWrapper {
    grid-template-columns: 1fr 100px 1fr 100px;

    > header {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-template-columns: 1fr 100px 1fr 100px;
    }

    > div {
      align-self: center;
      text-align: center;
      max-height: 300px;
      min-height: 48px;
      opacity: 1;
      // overflow: hidden;
      transition-property: opacity, max-height;
      transition-duration: .4s;
      transition-timing-function: linear;
      border-bottom: 1px solid $ActiveDDNav;
      display: flex;
      align-items: center;
      justify-content: left;
      padding: 4px 10px;

      &.name {
        padding-left: 10px;
        justify-content: left;
        text-align: left;
      }
    }
  }

  .no-students {
    padding: 18px 10px;
    grid-column: 1/5;
    margin-bottom: 40px;
    border-bottom: 1px solid $ActiveDDNav;
  }
}

dbq-roster-grid {
  > .transformWrapper {
    > header {
      div:nth-child(2),
      div:nth-child(4) {
        text-align: left;
      }
      div:nth-child(3) {
        text-align: left;
        padding-left: 10px;
        box-sizing: border-box;
      }
    }
    .action {
      padding-right: 20px;
      text-align: left;
      border-right: 1px solid $ActiveDDNav;
    }
  }
}

dbq-student-grid {
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 7;
  // top: 3em;
  // position: relative;

  > .transformWrapper {
    position: relative;
    width: 100%;
    background-color: white;
    grid-template-columns: 1.25fr .75fr 1.25fr .75fr 1.25fr .75fr .5fr .75fr;

    > header {
      grid-column-start: 1;
      grid-column-end: 9;
      grid-template-columns: 1.25fr .75fr 1.25fr .75fr 1.25fr .75fr .5fr .75fr;
      background: $DashLtBlue;
    }

    > div {
      align-self: left;
      text-align: left;
      max-height: 300px;
      min-height: 48px;
      opacity: 1;
      transition-property: opacity, max-height;
      transition-duration: .4s;
      transition-timing-function: linear;
      border-bottom: 1px solid $ActiveDDNav;
      display: flex;
      align-items: center;
      justify-content: left;
      padding: 4px 10px;

      &.name {
        font-size: 12px;
      }
      &.date {
        border-right: 1px solid $Disabled;
      }

      .activity-icon {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        text-align: center;
        float: left;
        margin-left: auto;
        cursor: pointer;

        &.outline-primary {
          border: 3px solid $DashBBlue;
        }

        &.solid-primary {
          background-color: $DashBBlue;
          border: 3px solid $DashBBlue;
          color: $White;
        }

        &.solid-orange {
          background-color: $DashBOrange;
          border: 3px solid $DashBOrange;
        }

        svg {
          width: 14px;
          height: 14px;
          margin-top: -6px;
          fill: $White;
        }
      }

      &.status-buttons {
        .uk-button {
          width: 70px;
        }
      }

      .uk-button-small {
        font-size: 11px;
      }
    }

    .no-students {
      padding: 10px;
      grid-column: 1/9;
      margin-bottom: 40px;
    }
  }

  &[should-fade] .transformWrapper {
    opacity: 0;
  }

  &[hidden] .transformWrapper {
    opacity: 0;
  }

  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 0;
    line-height: 20px;
    font-size: 20px;
    text-transform: uppercase;
    vertical-align: middle;
    min-height: auto;
  }

  .action-buttons {
    width: 100%;
    margin: 20px 0px;
    text-align: left;
    grid-column: 1/9;

    .btn-right {
      float: right;
      margin-left: 5px;
    }

    .uk-button-small {
      font-size: 11px;
    }
  }

  footer {
    margin-top: 60px;
    text-align: left;
    grid-column: 1/9;
    position: inherit;
    padding: 0px;
    background-color: $White;

    .legend {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      margin-top: 00px;
      min-height: 34px;
      border: 1px solid $Disabled;
      border-radius: 4px;
      padding: 14px 20px 7px;

      .legend-icon {
        // float: right;
        margin-left: 20px;
        font-size: 12px;

        &.legend-text-only {
          height: 22px;
        }

        .legend-icon-border {
          width: 20px;
          height: 20px;
          border-radius: 100%;
          text-align: center;
          float: left;
          margin-right: 4px;
          margin-top: -4px;

          &.outline-primary {
            background-color: $White;
            border: 3px solid $DashBBlue;
          }

          &.solid-primary {
            background-color: $DashBBlue;
            border: 3px solid $DashBBlue;
            color: $White;
          }

          &.solid-orange {
            background-color: $DashBOrange;
            border: 3px solid $DashBOrange;
          }

          svg {
            width: 14px;
            height: 14px;
            margin-top: 0px;
            fill: $White;
          }
        }
      }
    }
  }
}

dbq-teacher-grid {
  > .transformWrapper {
    grid-template-columns: 1fr 140px 120px;

    > header {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-template-columns: 1fr 140px 120px;
    }

    .access-level {
      background-color: $GreyLite;
      color: $GreyDark;
      font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 0;
      min-height: 10px;
      height: 20px;
      width: 20px;
      text-align: center;
      padding: 0 1px 0 0;
      border-radius: 100%;
      margin-left: 2px;
      margin-right: 4px;
      margin-top: -2px;
    }

    .access {
      padding-left: 10px;
      justify-content: left;
      text-align: left !important;
    }

    .primary-inst {
      background: rgba($StickyGreen, 0.15);
    }
  }

  .no-secondary-teacher {
    padding: 18px 10px;
    grid-column: 1/4;
    border-bottom: 1px solid $ActiveDDNav;
  }

  .invite-button {
    width: 170px;
    margin-top: 40px;
  }
}

dbq-assgn-templates-grid,
dbq-district-templates-grid,
dbq-district-rubrics-grid,
dbq-custom-comment-grid {
  > .transformWrapper {
    > header {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
}

dbq-assgn-templates-grid,
dbq-essaybuilder-grid,
dbq-district-templates-grid,
dbq-district-rubrics-grid,
dbq-custom-comment-grid,
dbq-essaybuilder-grid,
dbq-shared-manage,
dbq-rubrics-shared-manage,
dbq-shared-imported-grid,
dbq-shared-by-me-grid,
dbq-rubrics-shared-imported-grid,
dbq-rubrics-shared-by-me-grid {
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 4;

  .comment-directions {
    padding: 16px 2px 20px 2px;
    border-bottom: 1px solid $ActiveDDNav;
    margin-bottom: 30px;
    font-size: 12px;
    line-height: 18px;
  }

  > .transformWrapper {
    position: relative;
    width: 100%;
    background-color: white;

    > div {
      align-self: center;
      text-align: center;
      max-height: 300px;
      min-height: 48px;
      height: 100%;
      opacity: 1;
      transition-property: opacity, max-height;
      transition-duration: .4s;
      transition-timing-function: linear;
      border-bottom: 1px solid $ActiveDDNav;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;

      &.comment,
      &.title {
        padding-left: 10px;
        justify-content: left;
        text-align: left;

        .comment-text {
          margin-left: 20px;
        }
      }

      &.action {
        padding-right: 10px;
        justify-content: flex-end;
        text-align: right;


        dbq-dropdown {
          align-self: center;
          height: unset;
        }

      }
    }
    .color-holder {
      padding-left: 40px;
    }

    .action {

      a.uk-button {
        margin-left: 4px;

        &.edit,
        &.save {
          width: 60px;
        }

        &.delete {
          width: 80px;
          background-color: $Disabled;
          color: $Text;

          &:hover {
            background-color: $ActiveDDNav;
          }
        }
        &.cancel {
          width: 80px;
          background-color: $Disabled;
          color: $Text;

          &:hover {
            background-color: $ActiveDDNav;
          }
        }
      }
    }
  }

  &[should-fade] .transformWrapper {
    opacity: 0;
  }

  &[hidden] .transformWrapper,
  &[hidden] .visibilityWrapper {
    opacity: 0;
    height: 0;
  }

  .color {
    display: inline-block;
    border: 2px solid black;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    padding: 0;
    line-height: 15px;
    font-size: 15px;
    text-transform: uppercase;
    vertical-align: middle;
    min-height: auto;
    opacity: .5;
    margin-right: 4px;

    &[active],
    &:hover:not([disabled]) {
      opacity: 1;
      transform: scale(1.2, 1.2);
      transition: transform .4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &[color=red] {
      background-color: $annNavRed;
    }

    &[color=orange] {
      background-color: $annNavOrange;
    }

    &[color=yellow] {
      background-color: $annNavYellow;
    }

    &[color=green]{
      background-color: $annNavGreen;
    }

    &[color=blue] {
      background-color: $annNavBlue;
    }

    &[color=purple] {
      background-color: $annNavPurple;
    }

    &[color=gray] {
      background-color: $annNavGray;
    }

    &[color=black]{
      background-color: $annNavBlack;
    }

    &[color=white]{
      background-color: $annNavWhite;
    }
  }
}

dbq-shared-manage,
dbq-rubrics-shared-manage {
  #wrapperTable {
    padding-bottom: 150px;
  }
}

dbq-assgn-templates-grid,
dbq-district-templates-grid {
  > .transformWrapper {
    grid-template-columns: minmax(200px, 8fr) 1fr 280px;

    > header {
      grid-template-columns: minmax(200px, 8fr) 1fr 280px;

      .header-item-1 {
        text-align: center;
        // compensate for header-item padding
        margin-left: -10px;
      }
    }
  }
  .no-assignments {
    grid-column: 1/4;
  }
}

dbq-district-rubrics-grid {
  > .transformWrapper {
    grid-template-columns: minmax(200px, 8fr) repeat(5, 3fr) 150px;
    margin-bottom: 50px;

    @media only screen and (max-width: 959px){
      // needed for .dropMenu visiblity
      padding-bottom: 80px;
    }

    > header {
      grid-column-start: 1;
      grid-column-end: 8;
      grid-template-columns: minmax(200px, 8fr) repeat(5, 3fr) 150px;
    }

    .district-action {
      justify-content: flex-start;
      text-align: left;
    }

    .no-assignments {
      grid-column: 1/8;
      margin-bottom: 0px;

      .no-assignments-text {
        margin-bottom: 0px;
      }
    }


    > div {
      &.action {
        justify-content: flex-start;
        text-align: left;

        dbq-dropdown {
          display: inline;
          flex-wrap: inherit;
          margin-left: 0;
          align-self: center;
          height: unset;

          .dropTitle {
            text-align: center;
            padding: 0px 12px;
            line-height: 30px;
            display: inline-block;
            height: auto;
          }
          &[active] .dropMenu {
            overflow-y: inherit;
            top: 30px;
            left: unset;
            right: 4px;
          }
        }
      }
    }
  }

  .margin-remove-left {
    margin-left: 0px;
  }
  .button-align-bottom {
    margin-top: auto;
  }

  .no-assignments {
    grid-column: 1/7;
    margin-bottom: 40px;
  }

  .no-assignments-text {
    padding: 18px 10px;
    border-bottom: 1px solid $ActiveDDNav;
    margin-bottom: 40px;
  }

  &[hidden] .transformWrapper,
  &[hidden] .visibilityWrapper {
    margin-bottom: 0px !important;
    padding-bottom: 0px;
  }
}

dbq-shared-imported-grid,
dbq-shared-by-me-grid {
  > .transformWrapper {
    // needed for .dropMenu visiblity
    padding-bottom: 55px;
  }
  &[hidden] {
    > .transformWrapper {
      padding-bottom: 0px;
    }
  }
}

dbq-shared-imported-grid {
  > .transformWrapper {
    grid-template-columns: minmax(200px, 6fr) repeat(3, 2fr) 4fr;

    > header {
      grid-column-start: 1;
      grid-column-end: 6;
      grid-template-columns: minmax(200px, 6fr) repeat(3, 2fr) 4fr;

      div {
        text-align: center;
      }
      div:nth-child(1),
      div:nth-child(5) {
        text-align: left;
      }
    }

    div.action {
      justify-content: flex-start;
      text-align: left;
    }

    .no-assignments {
      grid-column: 1/6;
    }
  }
}

dbq-shared-by-me-grid {
  > .transformWrapper {
    grid-template-columns: minmax(200px, 6fr) repeat(2, 2fr) 5fr;
    // to match dbq-shared-imported-grid
    padding-bottom: 55px;

    > header {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-template-columns: minmax(200px, 6fr) repeat(2, 2fr) 5fr;

      div {
        text-align: center;
      }
      div:nth-child(1),
      div:nth-child(4) {
        text-align: left;
      }
    }

    div.action {
      justify-content: flex-start;
      text-align: left;
    }

    .no-assignments {
      grid-column: 1/5;
    }
  }
}

dbq-rubrics-shared-imported-grid {
  > .transformWrapper {
    grid-template-columns: minmax(200px, 3fr) repeat(2, 2fr) 4fr;

    > header {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-template-columns: minmax(200px, 3fr) repeat(2, 2fr) 4fr;

      div {
        text-align: left;
      }
      div:nth-child(2),
      div:nth-child(3) {
        text-align: center;
      }
    }

    div.action {
      justify-content: flex-start;
      text-align: left;
      white-space: nowrap;
    }

    .no-assignments {
      grid-column: 1/4;
    }
  }
}

dbq-rubrics-shared-by-me-grid {
  > .transformWrapper {
    grid-template-columns: minmax(200px, 5fr) 2fr 3fr;

    > header {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-template-columns: minmax(200px, 5fr) 2fr 3fr;

      div {
        text-align: left;
      }
      div:nth-child(2) {
        text-align: center;
      }
    }

    div.action {
      justify-content: flex-start;
      text-align: left;
      white-space: nowrap;
    }

    .no-assignments {
      grid-column: 1/3;
    }
  }
}

dbq-shared-imported-grid,
dbq-shared-by-me-grid,
dbq-rubrics-shared-imported-grid,
dbq-rubrics-shared-by-me-grid {
  div.action {
    small {
      font-size: 70%;
    }

    dbq-dropdown {
      align-self: center;
      height: unset;
    }
  }
}


dbq-essaybuilder-grid {
  > .transformWrapper {
    grid-template-columns: minmax(200px, 6fr) repeat(4, 2fr) 4fr;

    > header {
      grid-column-start: 1;
      grid-column-end: 7;
      grid-template-columns: minmax(200px, 6fr) repeat(4, 2fr) 4fr;

      div {
        text-align: center;
      }
      div:nth-child(1),
      div:nth-child(6) {
        text-align: left;
      }
    }

    div.action {
      justify-content: flex-start;
      text-align: left;
    }
  }
}

dbq-custom-comment-grid {
  > .transformWrapper {
    grid-template-columns: 40px 2fr 2fr minmax(135px, 1fr);
    display: block;

    [dragging] *{
      pointer-events: none;
    }

    [draggable] {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }


    .dragWrapper,
    .newCommentWrap{
      display: grid;
      grid-template-columns: 40px 2fr 2fr minmax(135px, 1fr);
      grid-column-start: 1;
      grid-column-end: 5;
      box-sizing: border-box;
      > div {
        justify-content: left;
        display: flex;
      }

      .comment-text-new {
        width: 100%;
      }
      .comment-text {
        text-align: left;
        width: 100%;
        font-size: 14px;

        &[disabled] {
          border: 1px solid $White;
          background-color: $White;
          font-size: 14px;
          padding-left: 6px;
        }
        &:not([disabled]) {
          padding-left: 6px;
          border: 1px solid $GreyLite;
          background-color: $ActiveDDNav;
        }

      }
      a {
        transform: scale(1, 1) rotate(0deg);
        transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &[flash] {
          transform: scale(1.2, 1.2) rotate(-10deg);
          z-index: 100;
        }
      }
    }

    .newCommentWrap {
      .action {
        .uk-button.save {
          width: 144px;
        }
      }
    }

    .dragWrapper {
      cursor: move;
    }

    .uk-grid, .comment-directions {
      grid-column-start: 1;
      grid-column-end: 5;
      justify-content: flex-start;
      text-align: left;
    }

    > .uk-grid {
      border-bottom: none;
    }

    .comment-directions {
      // height: 2em;
      padding: 0px 6px 20px 6px;
    }

    > header {
      grid-template-columns: 40px 2fr 2fr minmax(135px, 1fr);
      box-sizing: border-box
    }
  }



  input {
    width: 100%;
    height: 24px;
    justify-content: center;
  }

  span[contenteditable] {
    // height: 30px;
    // max-width: 100%;
    display: inline-block;
    padding: 4px 6px;
    border: 1px solid #ddd;
    background: #fff;
    color: #444;
    -webkit-transition: all 0.2s linear;
    -webkit-transition-property: border, background, color, box-shadow, padding;
    transition: all 0.2s linear;
    transition-property: border, background, color, box-shadow, padding;
  }

  .display-order {
    font-weight: bold;
    font-size: 12px;
    color: $GreyDark;
    padding-left: 14px;
  }
}
