/* uk-overwrites.scss */

html {
  // reseting uikit v3's line-height
  line-height: 20px;
}

.uk-panel-title  {
  font-weight: bold;
}

.uk-nav-navbar>li>a:hover,
.uk-nav-navbar>li>a:focus { background: $u10; }

.uk-offcanvas {
  z-index: 6000;

  .uk-nav-offcanvas li a.active,
  .uk-nav-offcanvas li a.active:hover,
  .uk-nav-offcanvas li a.active:focus {
    color: $White;
    outline: none;
  }

  .uk-nav-header {
    padding: 0px;
    color: rgba($White,.75) !important;
  }

  .uk-offcanvas-bar .non-link {
    a {
      cursor: default;
    }

    em, i,
    b, strong {
      display: contents;
    }
  }
}

.truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

em { color: $Text;}
a { color: $u10;}
a.Definition { color: $Black;}
.allcaps { text-transform: uppercase; }
.smalltext { font-size: 12px; }


.uk-text-danger,
.uk-alert-danger { color: $DBQ!important; }

/* Forms */
// .uk-form-file input[type=file] {
//   position: absolute;
//   top: 0;
//   z-index: 1;
//   width: 100%;
//   opacity: 0;
//   cursor: pointer;
//   left: 0;
// }
// .uk-form-file {
//   vertical-align: middle;
//   position: relative;
//   overflow: hidden;
// }

.almostThere { color: $Green;}

/* Tables */
.uk-subnav-pill > * > a:focus,
.uk-subnav-pill > * > a:hover { background-color: $GreyLite; }

.uk-subnav-pill > .uk-active > a {
  color: $Text;
  background-color: $ActiveDDNav;
}

.table-tabs {
  th {
    font-weight: normal;
    font-size: 11px;
    background: $ActiveDDNav;
  }
  tbody tr { border-bottom: 1px solid $Disabled; }
  tbody tr:last-child { border-bottom: 0px solid $Disabled; }
  tbody td,
  tfoot td {
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: middle;
  }
  tfoot { border-top: 1px solid $Disabled; }
  tfoot tr { font-style: normal; }

  tbody tr.accessPrimary td {
    background: rgba($StickyGreen, 0.15);
  }
}

@media only screen and (max-width: 768px){
  .uk-modal-dialog {
    margin: 50px auto;
  }
}

.uk-label {
  display: inline-block;
  padding: 3px 10px 2px;
  background: $DashBBlue;
  line-height: 1.5;
  font-size: 12px;
  color: $White;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 0;
  text-transform: uppercase;
}
.uk-label-warning {
  background-color: $LabelWarning;
  color: $Text;
}

/* Sticky */
.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 50px;
  left: 0;
  z-index: 100;
}

.responsive-overflow-auto {
  @media only screen and (max-width: $breakpoint-small){
    overflow: auto;

    .uk-table th.sticky {
      position: unset;
    }
  }
}

/* Hidden on XS */
@media only screen and (max-width: 540px){
  .uk-hidden-extraSM { display: none !important; }
}

  // Flex order
@media only screen and (min-width: 768px){
  .uk-flex-first\@s {
      order: -1;
  }
  .uk-flex-last\@s {
    order: 99;
  }
}

//  error pages
.min-height\@m {
  @media (min-width: $breakpoint-medium) {
    height: 600px;
  }
}

.layers-dropdown {
  // width: 215px;
  // left: -148px !important; // Moves drop down to the LEFT to fix off-screen issue

  .annotation-toggleOn,
  .annotation-toggleOff {
    padding: 6px 0;
  }
}

/* browser-update.org overwrites for spacing */
#buorg.buorg {
  background-color: $DashLtBlue;
  border-top: 1px solid $GreyLite;
  border-bottom: 1px solid $GreyLite;
  position: fixed;
  top: 50px;
}
.buorg-pad {
  padding: 30px 80px !important;
  line-height: 1.2 !important;
}
.buorg-buttons {
  margin: 30px 15px 0 !important;
}
.buorg-icon {
  display: none !important;
}
.buorg-dismiss {
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 33%;
  right: 5%;
}

// v3 uk-overwrites
.uk-width-1-10 {
  width: 10%;
}
.uk-width-2-10 {
  width: 20%;
}
.uk-width-3-10 {
  width: 30%;
}
.uk-width-4-10 {
  width: 40%;
}
.uk-width-5-10 {
  width: 50%;
}
.uk-width-6-10 {
  width: 60%;
}
.uk-width-7-10 {
  width: 70%;
}
.uk-width-8-10 {
  width: 80%;
}
.uk-width-9-10 {
  width: 90%;
}
.uk-width-10-10 {
  width: 100%;
}

@media only screen and (max-width: 767px){
  .dbq-hidden-small, .dbq-visible-large {
    display: none;
  }
  .dbq-visible-small {
    display: inline-block;
  }
}
@media only screen and (min-width: 768px){
  .dbq-visible-small {
    display: none;
  }
}
@media only screen and (min-width: 960px){
  .dbq-hidden-large {
    display: none;
  }
}
.dbq-visible-except\@m {
  @media only screen and (min-width: 960px) and (max-width: 1199px){
    display: none;
  }
}


.uk-button {
  text-transform: none !important;

  &.uk-button-default {
    border-color: $Disabled;

    &:hover,
    &:focus {
      border-color: $ActiveDDNav;
    }
    &:active {
      border-color: $GreyLite;
    }
  }
}

.uk-card-default {
  box-shadow: unset;
}

.uk-badge {
  min-height: 18px;

  &.uk-badge-success {
    background-color: $DashBGreen;
  }
  &.uk-badge-warning {
    background-color: $BadgeWarning;
  }
}

.djhj-notification {
  border-radius: 0px !important;
}

.uk-margin-xsmall-right {
  margin-right: 5px;
}
