/* dbq-admin-rubric-wizard.scss */
.admin-portal {
  .header-create-rubric {
    font-size: 18px;
    font-weight: normal;
    color: $Text;
    background-color: $DashMdBlue;
    padding: 8px 8px;
  }

  .DARCtop {
    padding: 15px 15px 0px 15px;
    background: $White;
    border: 1px solid $Disabled;
    border-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .DARCbottom {
    padding: 0px 15px 15px 15px;
    background: $White;
    border: 1px solid $Disabled;
    border-top: 0px;
  }

  // rubric wizard
  .rubric-wizard {
    position: relative;
    width: 100%;
    max-height: unset;
    overflow-y: hidden;
    overflow-x: auto;
    margin: 3rem 0px;
    padding-bottom: 0.5rem;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;

    .rubric-wizard-group {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      margin-right: 1.5rem;

      &:last-child {
        margin-right: 0px;
      }
    }

    .rubric-wizard-group {
      &[flash] {
        animation-name: flash;
        animation-duration: 0.8s; /* or: Xms */
        animation-iteration-count: 1;
        // animation-direction: alternate; /* or: normal */
        // animation-timing-function: ease-out; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
        // animation-fill-mode: forwards; /* or: backwards, both, none */
        // animation-delay: 2s; /* or: Xms */
      }
    }

    &.analytic-build {
      .rubric-wizard-analytics-column {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        flex-direction: column;

        .rubric-wizard-analytic {
          width: 100%;
          // the height of .rubric-wizard-group's description
          height: 253px;
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          margin-right: 1.5rem;

          &:first-child {
            // the heights of .rubric-wizard-group's handle, level, amount
            // margin-top: calc(15px + 1.5rem + 32px + 30px + 1.5rem + 1.5rem);
            margin-top: calc(15px + 1.5rem + 33px + 23px);
          }

          &:last-child {
            margin-bottom: 0px;
          }
        }

        .rubric-wizard-level,
        .rubric-wizard-handle {
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: flex-start;
          height: 30px;
        }

        .rubric-wizard-handle {
          .rubric-wizard-item {
            min-width: unset;
            width: 15px;
            max-width: 15px;
            height: 30px;
            margin-top: 0px;
            margin-bottom: 1.5rem;

            &:last-child {
              margin-bottom: 0px;
              margin-right: 1.5rem;
            }
          }
        }

        .rubric-wizard-amount {
          .rubric-wizard-item {
            margin-top: 0;
            margin-bottom: auto;
            margin-left: 1.5rem;
          }
        }
      }
    }

    .rubric-wizard-handle,
    .rubric-wizard-level,
    .rubric-wizard-title,
    .rubric-wizard-amount,
    .rubric-wizard-description {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      .rubric-wizard-item {
        display: inherit;
        min-width: 200px;
        width: 200px;
        max-width: 200px;
        margin-right: 1.5rem;
        border: 1px solid $DashBBlue;
        box-sizing: border-box;

        &:last-child {
          margin-right: 0px;
        }

        input,
        textarea {
          width: 100%;
          border: 0px;
        }
        textarea {
          height: 100%;
          resize: none;
          margin-top: unset;
          border-radius: 0.5rem;

          &::-webkit-scrollbar-thumb {
            border-radius: 0.5rem;
          }
        }
      }
    }

    .rubric-wizard-level {
      .rubric-wizard-item {
        input.uk-input {
          height: 30px;
        }
      }
    }

    .rubric-wizard-level,
    .rubric-wizard-amount {
      .rubric-wizard-item {
        min-width: 202px;
        width: 202px;
        max-width: 202px;
      }
    }

    .rubric-wizard-amount {
      .rubric-wizard-item {
        text-align: center;
        align-self: center;
        justify-content: center;
        height: 30px;
      }
    }

    .rubric-wizard-handle {
      .rubric-wizard-item {
        width: 202px;
        max-width: 202px;
        border: 0px;
        outline: 5px solid $DashMdBlue;
        outline-offset: -5px;
        background-color: $DashBBlue;
        height: 15px;
        margin-bottom: 1.5rem;
        cursor: grab;

        &:hover,
        &:focus {
          outline: 5px solid $DashBBlue;
          background-color: $DashMdBlue;
        }
      }
    }

    .rubric-wizard-amount,
    .rubric-wizard-description {
      .rubric-wizard-item {
        margin-top: 1.5rem;
      }
    }

    .rubric-wizard-description {
      .rubric-wizard-item {
        min-height: 300px;
        height: 300px;
        border-radius: 0.5rem;
      }
    }

    &.analytic-build {
      .rubric-wizard-description {
        flex-direction: column;
        a {
          width: 80px;
          align-self: flex-end;
          margin-right: 15px;
        }

        .rubric-wizard-item {
          min-height: 200px;
          height: 200px;
          border-radius: 0.5rem;

          &[disabled] {
            background-color: $GreyLite;
            border-color: $GreyLite;

            textarea {
              display: none;
              pointer-events: none;
            }
          }
        }
      }
    }

    .levels-btn-txt-vertical {
      display: none;
    }

    dbq-dropdown {
      .dropTitle {
        margin-left: 0px;
        display: inherit;
        height: 30px;
      }

      .dropMenu {
        min-width: unset;
        width: 202px;
        top: 30px;
        left: unset;
        right: calc(50% - 16px);
        background-color: $DashBBlue;
        color: $White;
      }

      &[active] {
        .dropTitle.uk-button.uk-button-default {
          border-color: $DashBBlue;
        }
      }
    }

    // preview mode
    &.wizard-preview {
      // replace with disbaled forms instead
      pointer-events: none;

      .rubric-wizard-item {
        border: unset;
      }

      .rubric-wizard-handle {
        display: none;
      }

      .rubric-wizard-level {
        .rubric-wizard-item {
          margin-bottom: 0px;

          input {
            text-align: center;
          }

          dbq-dropdown {
            display: none;
          }
        }
      }

      .rubric-wizard-amount {
        .rubric-wizard-item {
          margin-top: 0px;
        }
      }

      .rubric-wizard-description {
        .rubric-wizard-item {
          border: 1px solid $DisabledTab;
        }
      }
    }
  }

  .rubric-overall-score {
    display: none;
  }

  .rubric-instructions,
  .rubric-overall-score {
    // preview mode
    &.wizard-preview {
      display: block;
      pointer-events: none;
      margin-bottom: 1.5rem;

      input {
        text-align: center;
      }
      input,
      textarea {
        border: 1px solid $DisabledTab;
      }
      textarea {
        resize: none;
      }
    }
  }

  dbq-holistic-rubric-builder,
  dbq-analytic-rubric-builder {
    &[show-score] {
      .rubric-overall-score {
        display: block;
        margin-bottom: 1.5rem;
      }
    }

    // &[vertical] {
    //
    // }
  }

  dbq-holistic-rubric-builder {
    &[vertical] {
      .rubric-wizard {
        flex-direction: row;
        flex-wrap: wrap;

        .rubric-wizard-group {
          width: calc(100% - 2px);
          flex-wrap: nowrap;
          flex-direction: row;
          margin-right: 0px;
          margin-bottom: 1.5rem;

          &:last-child {
            margin-bottom: 0px;
          }
        }

        .rubric-wizard-handle,
        .rubric-wizard-level,
        .rubric-wizard-title,
        .rubric-wizard-amount,
        .rubric-wizard-description {
          flex-wrap: wrap;
          flex-direction: column;

          .rubric-wizard-item {
            min-width: unset;
            max-width: unset;
            margin-bottom: 1.5rem;

            &:last-child {
              margin-bottom: 0px;
              // margin-right: 1.5rem;
            }
          }
        }

        .rubric-wizard-handle,
        .rubric-wizard-level,
        .rubric-wizard-title,
        .rubric-wizard-amount {
          justify-content: flex-start;
        }

        .rubric-wizard-handle {
          .rubric-wizard-item {
            width: 15px;
            max-width: 15px;
            height: 30px;
          }
        }

        .rubric-wizard-amount {
          .rubric-wizard-item {
            min-width: unset;
            width: 75px;
            max-width: unset;
          }
        }

        .rubric-wizard-description {
          width: 100%;

          .rubric-wizard-item {
            min-height: unset;
            height: 150px;
            max-height: unset;
            width: 100%;
          }
        }

        .rubric-wizard-handle,
        .rubric-wizard-amount,
        .rubric-wizard-description {
          .rubric-wizard-item {
            margin-top: 0px;
          }
        }

        .levels-btn-txt-horizontal {
          display: none;
        }
        .levels-btn-txt-vertical {
          display: inline;
        }
      }
      .rubric-wizard {
        .rubric-wizard-description {
          .rubric-wizard-item {
            min-width: 250px;
          }
        }

        .rubric-wizard-handle,
        .rubric-wizard-level,
        .rubric-wizard-title,
        .rubric-wizard-amount,
        .rubric-wizard-description {
          .rubric-wizard-item {
            &:last-child {
              margin-bottom: 0px;
              margin-right: 1.5rem;
            }
          }
        }
      }
    }
  }

  dbq-analytic-rubric-builder {
    .analytic-build {
      .rubric-wizard-description {
        .btn-subtle-primary {
          position: relative;
        }
      }
    }


    .analytic-build {

      .rubric-wizard-group {
        .rubric-wizard-description {
          .rubric-wizard-item {
            margin-right: 0px;
          }
        }
      }

      .rubric-wizard-amount {
        .rubric-wizard-item {
          min-width: unset;
          width: 202px;
          max-width: unset;
        }
      }
    }


    &[show-score][horizontal] {
      .analytic-build {
        .rubric-wizard-analytics-column {
          .rubric-wizard-analytic {
            &:first-child {
              // the widths of .rubric-wizard-group's handle, level, amount
              margin-top: calc(15px + 1.5rem + 33px + 23px + 1.5rem + 30px);
            }
          }
        }
      }
    }

    &[vertical][show-score] {
      .analytic-build {
        .rubric-wizard-amount {
          .rubric-wizard-item {
            min-width: unset;
            width: 75px;
            max-width: unset;
          }
        }
      }
    }

    // &[vertical] {
    //   .analytic-build {
    //     .rubric-wizard-analytics-column {
    //       display: flex;
    //       justify-content: space-between;
    //       flex-wrap: nowrap;
    //       flex-direction: row;

    //       .rubric-wizard-analytic {
    //         // the width of .rubric-wizard-group's description
    //         width: 202px;
    //         height: unset;
    //         display: flex;
    //         flex-wrap: wrap;
    //         flex-direction: column;
    //         margin-right: 1.5rem;
    //         margin-bottom: 1.5rem;

    //         &:first-child {
    //           margin-top: unset;
    //           // the widths of .rubric-wizard-group's handle, level
    //           margin-left: calc(30px + 15px + 202px);
    //         }

    //         &:last-child {
    //           margin-right: 0px;
    //         }
    //       }

    //       .rubric-wizard-handle,
    //       .rubric-wizard-level,
    //       .rubric-wizard-amount {
    //         .rubric-wizard-item {
    //           min-width: unset;
    //           width: 200px;
    //           max-width: unset;
    //         }
    //       }

    //       .rubric-wizard-handle {
    //         .rubric-wizard-item {
    //           height: 15px;
    //           margin-top: 0px;
    //           margin-bottom: 0px;
    //         }
    //       }

    //       .rubric-wizard-amount {
    //         .rubric-wizard-item {
    //           margin-top: 1.5rem;
    //           margin-left: 0px;
    //         }
    //       }
    //     }

    //     .rubric-wizard-group {
    //       .rubric-wizard-handle,
    //       .rubric-wizard-level,
    //       .rubric-wizard-amount {
    //         .rubric-wizard-item {
    //           margin-right: 15px;

    //           &:last-child {
    //             margin-right: 15px;
    //           }
    //         }
    //       }
    //     }

    //     .rubric-wizard-description {
    //       margin-right: 0px;

    //       &:last-child {
    //         margin-right: 0px;
    //       }

    //       .rubric-wizard-item {
    //         min-width: unset;
    //         width: 200px;
    //         max-width: unset;
    //         min-height: 200px;
    //         height: 200px;
    //         max-height: 200px;
    //         margin-bottom: 0px;
    //       }

    //       a {
    //         margin-right: 35px;
    //       }
    //     }
    //   }
    // }

    // &[vertical][show-score] {
    //   .analytic-build {
    //     .rubric-wizard-analytics-column {
    //       .rubric-wizard-analytic {
    //         &:first-child {
    //           margin-top: unset;
    //           // the widths of .rubric-wizard-group's handle, level, amount
    //           margin-left: calc(30px + 15px + 202px + 15px + 60px + 15px);
    //         }
    //       }
    //     }
    //   }
    // }
  }

  input[type="text"]#id_title {
    margin-bottom: 0px !important;
  }

  input[type="number"] {
    &#id_performance_levels,
    &#id_scoring_increment,
    &#id_performance_criteria {
      width: 65px;
      text-align: center;
    }
  }
}

[flash] {
  animation-name: flash;
  animation-duration: 1s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: alternate; /* or: normal */
  animation-timing-function: cubic-bezier(
    0.64,
    0.57,
    0.87,
    2.23
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  // animation-fill-mode: forwards; /* or: backwards, both, none */
  // animation-delay: 2s; /* or: Xms */
}
[flash-two] {
  animation-name: flash-two;
  animation-duration: 1s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: alternate; /* or: normal */
  animation-timing-function: cubic-bezier(
    0.64,
    0.57,
    0.87,
    2.23
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  // animation-fill-mode: forwards; /* or: backwards, both, none */
  // animation-delay: 2s; /* or: Xms */
}

[unflash] {
  animation-name: flash;
  animation-duration: 0.4s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: reverse; /* or: normal */
  animation-timing-function: cubic-bezier(
    0.64,
    0.57,
    0.87,
    2.23
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  // animation-fill-mode: forwards; /* or: backwards, both, none */
  // animation-delay: 2s; /* or: Xms */
}
[unflash-row] {
  animation-name: flash-two;
  animation-duration: 0.4s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: reverse; /* or: normal */
}

@keyframes flash {
  0% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}

@keyframes flash-two {
  0% {
    opacity: 0;
    max-height: 0;
  }
  100% {
    opacity: 1;
    max-height: 100%;
  }
}

//specificity hack that is better than using !important
.ql-container.ql-container,
.ql-container .ql-editor.ql-editor {
  height: auto;
}

.tight-letter-spacing {
  letter-spacing: -1px;
}
