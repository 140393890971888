
body {
  // margin-tops have been increased by 10 for new topNav height
  margin-top: 60px;

  &.withSecondaryNav{
    margin-top: 102px;

    .uk-modal-page & {
      margin-top: 77px;
    }
  }

  &.withTertiaryNav{
    margin-top: 142px;

    // start: styles for side-by-side
    overflow-x: auto;

    .container-wrapper {
      margin-right: auto;
      margin-left: 175px;
    }
    // end: styles for side-by-side
    
    .uk-modal-page & {
      margin-top: 142px;
    }
  }

  &.withFourthNav{
    margin-top: 192px;

    .uk-modal-page & {
      margin-top: 167px;
    }
  }

  &.body-login {
    @extend .uk-height-1-1;
    margin-top: 0;
  }
}


.topNav-login,
.topNav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: block;
  width: 100%;
  height: 50px;
  font-family: $base-body-font-family ;
  box-sizing: border-box;

  nav {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    flex-direction: row;
    width: 100%;
    background-color: $Black;
    height: 50px;
    box-sizing: border-box;

    &.annotation {
      overflow: visible;
    }
  }

  nav > a {
    display: inline-flex;
    height: 100%;
    color: $White;
    align-items: center;
    padding: 0 15px;
    position: relative;
    // previous uk-kit version declarations
    font-size: 14px;
    line-height: 20px;

    &.topNav-variablelink {
      width: 30%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (min-width: 579px) and (max-width: 767px) {
        width: 20%;
      }
      @media (max-width: 578px) {
        width: 10%;
      }

      &.non-link,
      &.non-link:hover,
      &.non-link:focus {
        cursor: default;
        color: rgba($White,.75) !important;
        background-color: $Black !important;

        em, i,
        b, strong {
          color: unset;
        }
      }
    }
    &.logout {
      align-self: flex-end;
      margin-left: auto;
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:focus {
      background: $Disabled;
    }

    &:hover,
    &:focus {
      color: $White;
      background-color: lighten($Black, 20%);
      outline: none;
      text-decoration: none;
    }

    &.active {
      color: rgba($White,.75) !important;
      // background-color: lighten($Black, 20%);

      span.nav-text {
        border-bottom: 2px solid rgba($White,.75);
        padding-bottom: 4px;
        margin-top: 5px;
      }
    }
  }

  .topNav-dropDown,
  .secondarynav-dropDown,
  .topNav-dropDownRight {
    display: inline-flex;
    height: 100%;
    color: $White;
    align-items: center;
    max-width: 400px;
    position: relative;

    .dropTitle{
      font-size: 14px;
      line-height: 20px;

      svg.icon {
        height: 16px;
        min-height: 16px;
        width: 16px;
        min-width: 16px;
        fill: $White;

        &.icon-sm-gap-end {
          @media (min-width: 641px){
            margin-right: 5px;
          }
        }
      }
    }
  }

  .topNav-dropDownRight {
    li > a {
      height: 50px;
      display: inline-flex;
      color: $White;
      align-items: center;
    }

    ul.uk-dropdown  {
      background-color: lighten($Black, 20%);

      li > a {
        display: block;
        height: 30px;
        padding: 10px 15px 0px;
      }
    }
    .uk-nav-divider {
      margin: 5px 0;
      border-top: 1px solid rgba($White,.15);
    }
  }
  .topNav-dropDownRight > li:hover > a,
  .topNav-dropDownRight > li:focus > a,
  .topNav-dropDownRight > li:active > a {
    color: $White;
    background-color: lighten($Black, 20%);
  }
  .topNav-dropDownRight > li.uk-open > a {
    color: $White;
    background-color: lighten($Black, 20%);
  }
}

.secondarynav-dropDown {
  max-width: calc(60% - 259px);

  &.secondarynav-variablelink {
    width: 126px;

    .truncated {
      max-width: 89px;
    }
    // @media (max-width: 959px) {
    //   max-width: 216px;
    //
    //   .truncated {
    //     max-width: 200px;
    //   }
    // }
    @media (max-width: 767px) {
      // includes ahref's padding-right
      max-width: 121px;

      .truncated {
        max-width: 85px;
      }
    }
  }
}

.hasTeacherComments {
  background-color: $annoEditNav;
  border-radius: 50% 50%;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: auto;
}

.container-secondarynav .topNav-dropDownRight{
  align-items: center;
  max-width: unset;
}

.brand.brand {
  height: 100%;
  color: $White;
  background: $DBQ;
  text-decoration: none;
  font-family: serif;
  font-size: 135%;
  margin: 0 0;
  padding: 0 15px;
  letter-spacing: initial;

  &:hover,
  &:focus {
    background: $Red;
    color: $White;
  }
}

.portal.portal {
  height: 100%;
  color: $White;
  background: $DBQ;
  border-left: 1px solid $White;
  text-decoration: none;
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: normal;
  margin: 0;
  padding: 0 15px;
  letter-spacing: initial;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &:hover,
  &:focus {
    background: $Red;
    color: $White;
  }

  div {
    font-weight: normal;
    font-style: italic;
    align-self: flex-end;
    font-size: 12px;
    margin-left: 15px;
  }
}

.container-secondarynav .secondarynav-dropDown,
.topNav-dropDown,
.topNav-dropDownRight {
  display: flex;
  align-items: center;
}

.container-secondarynav .secondarynav-dropDown.secondarynav-assignments {
  word-wrap: break-word;
  line-height: 1em;

}

.container-secondarynav .secondarynav-dropDown.secondarynav-assignments.annotation-dropDownToggle{
  // width: 100px;
  //
  // @media (max-width: 640px) {
  //   width: 60px;
  // }
  width: auto;
}

.container-secondarynav .topNav-dropDownRight {
  color: $White;

  &:hover {
    background: $GreyDark;
    color: $White;
  }
}

.topNav-dropDownRight {
  align-self: flex-end;
  // margin-left: auto;
  //
  // &:first-of-type {
  //   margin-left: auto;
  // }

  + .topNav-dropDownRight ,
  &:last-of-type + .logout,
  &:last-of-type + .right-side {
    margin-left: 0;
  }
}

.secondarynav-dropDownCenter.secondarynav-dropDownCenter{
  @extend .secondarynav-dropDown;
  margin: 0 auto;
  align-self: center;

  + .side-nav {
    margin-left: 0;
  }
}

//  Secondary Nav
.container-secondarynav.container-secondarynav {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-basis: 100%;
  height: 40px;
  background: $GreyDark;
  color: $White;

  i {
    margin-left: 5px;
    flex-basis: 10px;
  }

  span {
    display: flex;
    flex-wrap: wrap;
    // overflow: hidden;
    flex-basis: 100%;
    align-content:  center;

  }

  dbq-dropdown {
    align-self: flex-start;
    margin-left: 0;

    &[active] .dropTitle,
    .dropTitle:hover {
      color: $Text;
      background-color: $ActiveDDNav;
      text-decoration: none;

      svg.icon {
        fill: $Text;
      }
    }

    &.secondarynav-dropDown-right {
      align-self: flex-end;
      margin-left: auto;

      // &:last-of-type + .audioPlayer-wrapper + .right-side,
      // &.annotation-dropDownToggle + .right-side  {
      //   margin-left: 0;
      // }
    }

    &.secondarynav-assignments {
      .dropTitle {
        margin-right: 0px;
      }
    }
  }
  .dropTitle{
    color: $White;

    &.audios-baselineFix {
      line-height: 0px;
      padding-right: 5px;
    }
  }
  > a{
    display: flex;
    padding: 0 10px;
    color: $White;
    max-width: 100%;
    align-items: center;
    // box-sizing: content-box;

    .secondarynav-assignments & {
      width: calc(100% - 20px);
      max-width: unset;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:focus {
      color: rgba($White, .3);
      cursor: default;
    }
  }
  a.secondarynav-done.secondarynav-done {
    border: 3px soild $White;
    display: flex;
  }
}

a.side-nav {
    flex-basis: 30px;
    width: 24px;
    padding: 0 !important;
}

.container-secondarynav > a,
.side-nav a {
  color: rgba($White, 0.6);
  font-family: sans-serif;
  font-size: 14px;
  line-height: 14px;
  text-decoration: none;


  &:hover {
    color: $Text;
  }

  &.text-link {
    svg.icon {
      height: 16px;
      min-height: 16px;
      width: 16px;
      min-width: 16px;
      fill: $White;

      @media (min-width: 641px) and (max-width: 961px){
        margin-right: 5px;
      }
    }

    &:hover,
    &:focus {
      svg.icon {
        fill: $Text;
      }
    }
  }
}

// .left-side + sw-teacher-student-toggle + .right-side {
//   margin-left: 0px;
// }
//
// .left-side + dbq-dropdown.secondarynav-variablelink {
//
//   &+ dbq-dropdown.secondarynav-dropDown.DAS {
//     &+ sw-teacher-student-toggle {
//       &+ .right-side {
//         @media (min-width: 760px){
//           margin-left: 121px;
//         }
//         @media (max-width: 759px){
//           margin-left: 60px;
//         }
//       }
//     }
//   }
//
//   &+ sw-teacher-student-toggle {
//     &+ .right-side {
//       @media (min-width: 760px){
//         margin-left: 121px;
//       }
//       @media (max-width: 759px){
//         margin-left: 60px;
//       }
//     }
//   }
//
//   &+ sw-teacher-student-toggle {
//     &+ .secondarynav-audio {
//       @media (min-width: 760px){
//         margin-left: 0px;
//       }
//     }
//   }
// }
//
// .left-side + .secondarynav-text-link {
//   &+ sw-teacher-student-toggle {
//     &+ .right-side {
//       margin-left: 97px;
//     }
//   }
// }

.right-side{
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-left: auto;
  text-indent: -3px;

  height: 100%;
  padding-left: 0 !important;

  span {
    flex-basis: unset !important;
  }
}

.right-side,
.left-side {
  .fontawesome {
    font-size: 20px;
    margin-top: -4px;
  }
}

a.side-nav[disabled],
a.side-nav[disabled]:hover {
  color: rgba(255,255,255,0.3) !important;
  pointer-events: none;
  cursor: default;
}

a.switchAudio-link.switchAudio-link.switchAudio-link {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  color: $Text;

  &:hover {
    color: $White;
  }
}

a.manageReturn,
a.manageReturn-alt {
  background-color: #00a8e6;
  color: $White;
  text-transform: capitalize;

  &:hover {
    color: $Text;
  }
}

a.manageReturn-alt {
  background-color: $annoEditNav;
}

@media (max-width: 1370px) {

  .essaybuilderNav .dropTitle span{
    max-width: 85px;
    overflow: hidden;

  }
}

a.manage-return {
  display: inline-flex;
  height: 100%;
  color: #fff;
  align-items: center;
  margin-left: auto;
  padding: 0 15px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  background-color: $annoEditNav;

  svg {
    fill: $White;
    height: 16px;
    width: 16px;
    margin-left: -2px;
  }

  &:hover,
  &:focus {
    color: $annoNav;
    background-color: $White;

    svg {
      fill: $annoNav;
    }
  }
}

// english/spanish secondary nav toggle
dbq-lang-modal {
  a.lang-toggle {
    border: 1px solid $DisabledTab;
    color: $White;
    font-size: 14px;
    text-decoration: none;
    display: flex;
    margin-top: 7px;
    margin-left: 5px;
    padding: 2px 6px;

    &:hover,
    &:focus {
      color: $Text;
      border: 1px solid $White;
      background: $White;
    }
  }
}

.centeredNavItem {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
}

sw-teacher-student-toggle {
  .user-toggle {
    border: 1px solid $DisabledTab;
    color: $White;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
    display: flex;
    margin-top: 7px;
    margin-bottom: 6px;
    margin-left: 0px;
    padding: 0px 6px;

    &:hover,
    &:focus,
    &.active,
    &[active] {
      color: $Text;
      border: 1px solid $White;
      background-color: $White;
    }

    &:first-child {
      border-right: 0px;
    }

    span {
      font-size: 14px;
      display: inline-block !important;
    }
  }
}

sw-teacher-student-toggle + a.side-nav.right-side {
  margin-left: 0px;
}

.nav-font-size {
  font-size: 14px;
  line-height: 20px;
}

.word-space-left {
  margin-left: 5px;

  .brand & {
    margin-left: 10px;
  }
}
.word-space-right {
  margin-right: 5px;
}
