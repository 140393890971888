.dashboard-content,
.platform-update {
  margin-top: 50px;

  .main-content {
    margin-bottom: 0 !important;

    .uk-button {
      background-color: $DashBBlue;
      color: $White;

      &:hover {
        background-color: lighten($DashBBlue,7%);
      }
    }
  }
}

.platform-update {
  img {
    border: 1px solid $GreyLite;
  }

  .md-text-center {
    @media (min-width: 768px) {
      text-align: center;
    }
  }
}

.background-primary,
.background-secondary {
  background: $White;
  padding: 30px 0;
}

.background-secondary {
  background: $Disabled;
}

.background-tertiary {
  // background: $DashBBlue;
  background: $DashLtBlue;
  padding: 30px 0;

  @media (min-width: 768px) {
    padding: 30px;
  }
}

.card-deck {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-flow: row wrap;
    margin-left: -15px;
    margin-right: -15px;
  }

  .card.card-link {
    background-clip: border-box;
    background-color: $White;
    border: 4px solid $Disabled;
    // box-shadow: 0 5px 10px rgba($Black,.08);
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    min-width: 0;
    position: relative;
    word-wrap: break-word;

    .background-secondary & {
      border: 4px solid darken($Disabled,2%);
    }

    @media (min-width: 768px) {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
      margin-bottom: 0;
      margin-left: 15px;
      margin-right: 15px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      border: 4px solid $DashBGreen;

      .fake-uk-btn {
        background-color: #3ebaf1;
      }
    }

    .card-img {
      width: 100%;
    }

    .card-body {
      flex: 1 1 auto;
      padding: 1.25rem;

      .card-title {
        margin-bottom: .75rem;
        margin-top: 0;
        color: $Text;
      }

      .card-text {
        color: $Text;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .card-footer {
      padding: 1.25rem;
    }
  }
}

/* Text superimposed over Image */
.text-over-image {
  position: relative;
  text-align: center;

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba($Black,.50);
    padding: 5%;
    color: $White;
  }
}
