footer {
  width: 100%;
  text-align: right;

  .copyright {
    margin-right: 20px;
  }

  &.background-main {
    // margin-bottom: 15px;
    position: fixed;
    z-index: 4;
    bottom: 0;
    background-color: $ActiveDDNav;
    padding: 14px 0px;
  }

  &.background-secondary {
    position: inherit;
    text-align: left;
    margin-bottom: 0;
    background: lighten($Disabled,4%);

    .footer-content {
      padding: 0 30px;
      color: lighten($Text,15%);

      .footer-icon {
        font-size: 25px;
        margin-right: 5px;
      }

      .footer-text {
        flex-grow: 1!important;

        h5 {
          opacity: .80;
          margin-bottom: 10px;
        }

        a {
          display: block;
          color: $MobileNav;
          padding: 2px 0;

          &:hover,
          &:focus {
            color: $ebNav;
            text-decoration: underline;
          }
        }
      }
    }

    .uk-width-1-1\@s {
      @media (max-width: 767px) {
        margin-bottom: 25px;
      }

      &:nth-child(5n) {
        text-align: right;
        margin-bottom: 0;

        @media (max-width: 767px) {
          text-align: center;
        }
      }
    }
  }
}

footer {
  .body-login & {
    hr {
      margin-top: 0;
      border: 0;
    }
  }
}
