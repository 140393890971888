dbq-modal {
  opacity: 1;
  z-index: 500000;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1);

  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &[top] {
    align-items: flex-start;
    margin-top: 80px;
  }

  &[hidden] {
    display: flex !important;
    opacity: 0;
    pointer-events: none;

    > section {
      transform: scale(0.5, 0.8);
    }
  }

  > section,
  > section > header,
  > section > main,
  > section > footer {
    display: flex;
    flex-wrap: wrap;
    // width: 100%;

    h2 {
      margin-bottom: 0px;
    }
  }

  > section,
  > section > header {
    width: 100%;
  }

  > section > header button {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50% 50%;
    z-index: 100;
    background-color: $White;
    height: 24px;
    width: 24px;
    display: block;

    > svg {
      height: 16px;
      width: 16px;
      position: absolute;
      top: 5px;
      right: 3px;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $White;
      svg {
        fill: lighten($Text, 10%);
        use {
          fill: lighten($Text, 10%);
        }
        svg path {
          fill: inherit;
        }
      }
    }
  }

  > section {
    width: 80%;
    max-width: 800px;
    // min-width: 300px;
    max-height: 90%;
    padding: 1em;
    background-color: white;
    color: #444;
    will-change: transform;
    transform-origin: center;
    backface-visibility: hidden;
    transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: scale(1, 1);
    position: relative;
    overflow: hidden;
    // height: 100%;
  }

  .caption {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    bottom: 0;

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  &.ebModal > section,
  > section[maximize] {
    width: auto;
    overflow: hidden;
    max-width: 90%;
    height: 100%;
    justify-content: center;
  }

  &.ebModal > section {
    width: auto;
    overflow: hidden;
    max-width: 1000px;
    height: 100%;
    justify-content: center;
    width: 90%;
  }

  &.ebModal > section > main {
    height: 100%;
  }

  > section > main > picture > img {
    max-height: 90vh;
    max-width: 100%;
    height: auto;
    display: flex;
  }

  > section > main {
    max-height: 100%;
    justify-content: flex-start;
    overflow: auto;
    width: 100%;
    position: relative;

    .dbq-modal-body {
      margin: 2em 0;
      max-width: 800px;

      .input-classLink {
        width: 96%;
        padding: 6px;
        font-size: 14px;
      }

      .class-code {
        min-width: 150px;
        width: 30%;
        padding: 6px 0px;
        border-radius: 4px;
        background-color: rgba($DashLtBlue, 0.5);
        margin: 0 auto;
      }
    }

    .dbq-modal-footer {
      text-align: right;
      border-top: 1px solid $GreyLite;
      padding-top: 0;
      margin: 3em 0 0 0;
      justify-content: flex-end;
      max-width: 800px;

      .uk-button {
        margin-left: 10px !important;
      }
    }
  }

  &.ebModal > section > main {
    justify-content: center;
  }

  &[confirm] > section > main {
    margin: 2em 0;
  }

  > section > footer {
    text-align: right;
    border-top: 1px solid $GreyLite;
    padding-top: 1em;
    margin: 0;
    justify-content: flex-end;

    button {
      margin-left: 1em !important;
    }
  }
}

dbq-lang-modal {
  section {
    height: inherit;
  }
}

sw-blocking-error {
  display: contents;

  dbq-modal sw-sanitize,
  dbq-modal sw-sanitize > svg,
  dbq-modal sw-sanitize .body {
    display: flex;
    flex-wrap: wrap;
  }

  #sessionEnded svg {
    margin-top: 0;
    padding: 40px;
  }

  dbq-modal sw-sanitize > svg {
    width: 40%;
    padding-right: 0;
  }
  dbq-modal sw-sanitize > .body {
    width: 60%;
    margin-bottom: 2em;
  }
  dbq-modal sw-sanitize h2:not(.title) {
    margin: 0 0 1em 0;
    line-height: 1.3em;
  }

  dbq-modal sw-sanitize .title {
    font-size: 60px;
    font-weight: 100;
    line-height: 0.8em;
  }
}

.uk-modal.modal-share,
dbq-modal {
  .share-url {
    width: fit-content;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 12px;
    border-radius: 4px;
    background-color: rgba($DashLtBlue, 0.5);

    .copybutton {
      width: 100px;
      line-height: 38px;
    }
  }

  .uk-modal-body {
    padding: 15px;
  }

  .uk-modal-footer {
    text-align: right;
    margin-top: 2em;
    padding: 15px 0px 0px;
  }
}
