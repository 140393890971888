/* dbq-simple-quill.scss */
dbq-simple-quill {

  .ql-snow {
    &.ql-toolbar,
    &.ql-container {
      border: 1px solid $DashBBlue;
    }

    &.ql-toolbar {
      border-bottom: 1px dotted $DashBBlue;
    }
    &.ql-container {
      border-top: none;
    }

    &.ql-toolbar button:hover,
    &.ql-toolbar button:focus,
    &.ql-toolbar button.ql-active,
    &.ql-toolbar .ql-picker-label:hover,
    &.ql-toolbar .ql-picker-label:focus,
    &.ql-toolbar .ql-picker-label.ql-active,
    &.ql-toolbar .ql-picker-item:hover,
    &.ql-toolbar .ql-picker-item:focus,
    &.ql-toolbar .ql-picker-item.ql-selected {
      color: $DashBBlue;
    }

    &.ql-toolbar .ql-picker.ql-expanded .ql-picker-label {
      color: #ccc;
    }
    &.ql-toolbar .ql-picker:not(.ql-expanded) .ql-picker-label:hover,
    &.ql-toolbar .ql-picker:not(.ql-expanded) .ql-picker-label:focus {
      color: $DashBBlue;
    }

    &.ql-toolbar button:hover .ql-stroke,
    &.ql-toolbar button:focus .ql-stroke,
    &.ql-toolbar button.ql-active .ql-stroke,
    &.ql-toolbar .ql-picker-label:hover .ql-stroke,
    &.ql-toolbar .ql-picker-label:focus .ql-stroke,
    &.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
    &.ql-toolbar .ql-picker-item:hover .ql-stroke,
    &.ql-toolbar .ql-picker-item:focus .ql-stroke,
    &.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
    &.ql-toolbar button:hover .ql-stroke-miter,
    &.ql-toolbar button:focus .ql-stroke-miter,
    &.ql-toolbar button.ql-active .ql-stroke-miter,
    &.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
    &.ql-toolbar .ql-picker-label:focus .ql-stroke-miter,
    &.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
    &.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
    &.ql-toolbar .ql-picker-item:focus .ql-stroke-miter,
    &.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
      stroke: $DashBBlue;
    }

    &.ql-toolbar .ql-picker.ql-expanded .ql-picker-label:hover .ql-stroke,
    &.ql-toolbar .ql-picker.ql-expanded .ql-picker-label:focus .ql-stroke {
      stroke: #ccc;
    }

    &.ql-toolbar button:hover .ql-fill,
    &.ql-toolbar button:focus .ql-fill,
    &.ql-toolbar button.ql-active .ql-fill,
    &.ql-toolbar .ql-picker-label:hover .ql-fill,
    &.ql-toolbar .ql-picker-label:focus .ql-fill,
    &.ql-toolbar .ql-picker-label.ql-active .ql-fill,
    &.ql-toolbar .ql-picker-item:hover .ql-fill,
    &.ql-toolbar .ql-picker-item:focus .ql-fill,
    &.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
    &.ql-toolbar button:hover .ql-stroke.ql-fill,
    &.ql-toolbar button:focus .ql-stroke.ql-fill,
    &.ql-toolbar button.ql-active .ql-stroke.ql-fill,
    &.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
    &.ql-toolbar .ql-picker-label:focus .ql-stroke.ql-fill,
    &.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
    &.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
    &.ql-toolbar .ql-picker-item:focus .ql-stroke.ql-fill,
    &.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
      fill: $DashBBlue;
    }

    &.ql-toolbar .ql-picker.ql-expanded .ql-picker-label:hover .ql-fill,
    &.ql-toolbar .ql-picker.ql-expanded .ql-picker-label:focus .ql-fill {
      fill: #ccc;
    }
  }

  .ql-editor {
    transition: all 0.2s linear;
    transition-property: border, background, color, box-shadow, padding;

    &:focus {
      color: $DashBBlue;
      background-color: rgba($DashBBlue, .12);
    }
  }
}

//
.rubric-wizard-description {
  dbq-simple-quill {
    width: 100%;

    .ql-container {
      height: 100%;

      .ql-editor {
        // minus the height of the ql-toolbar = 47px
        height: calc(100% - 47px) !important;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        hyphens: auto;
      }
    }

    .ql-snow {
      &.ql-toolbar,
      &.ql-container {
        border: none
      }

      &.ql-toolbar {
        border-bottom: 1px dotted $DashBBlue;
      }
    }

    &[disabled] {
      .ql-toolbar,
      .ql-container {
        display: none;
        pointer-events: none;
      }
    }
  }
}
