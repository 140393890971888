// .binderLib-head {
//   @extend .uk-panel;
//   @extend .uk-width-1-1@m;
//   @extend .uk-margin-large-top;

//   h3 {
//     @extend .uk-panel-title;
//     line-height: 14px;

//     small {
//       font: {
//         weight: normal;
//         size: 12px;
//       }
//       color: lighten( $Text, 25% );
//     }
//   }
// }

// .binderLib-desc {
//   @extend .uk-margin-small-top;
// }

// .binderLib {
//   @extend .uk-grid;
//   @extend .uk-grid-collapse;
//   @extend .DBQOL;
// }

.binderWrap {
  // @extend .uk-width-medium;
  // @extend .uk-width-1-4;
  @media (min-width: 768px) {
    width: 25%;
  }
  @extend .uk-margin-top;
    box-sizing: border-box;
}


.binder {
  @extend .uk-card;
  padding: 15px;
  background: $GreyDark;
  border: 4px solid $Disabled;
  border-radius: 0;

  .binderWrap & {
    min-height: 315px;
    height: 100%;
  }

  h3 {
    color: $White;
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
  }
  &.mq-California h3,
  &.mq-Geography h3,
  &.mq-Louisiana h3 {
    color: $Text;
  }

  img {
    @extend .uk-responsive-width;
    @extend .uk-margin-top;
    @extend .uk-margin-bottom;
  }

  > a {
    @extend .uk-button;

    background: none;

    &:hover,
    &:focus,
    &:active {
      background: none;

      .fake-button,
      .fake-button {
        color: $White;
        background: none;
      }
    }

  }

  &.mq-Civics,
  &.mq-TrialCivics {
    background: $BinderCivics;
  }

  &.mq-Florida {
    background: $BinderFlorida;
  }

  &.mq-Literature-1,
  &.mq-TrialLiterature-1,
  &.mq-Literature-2,
  &.mq13-0-Lit3 {
    background: $BinderLiterature;
  }

  &.mq-Texas {
    background: $BinderTexas;
  }

  &.mq-US-1,
  &.mq-US-1a,
  &.mq-US-2,
  &.mq-US-2a,
  &.mq-TrialUS-2 {
    background: $BinderUS;
  }

  &.mq-US-DBQ {
    background: $BinderUS-DBQ;
  }

  &.mq-World-1,
  &.mq-World-2,
  &.mq-World-3 {
    background: $BinderWorld;
  }

  &.mq-World-DBQ,
  &.mq-TrialWorld-DBQ {
    background: $BinderWorld-DBQ;
  }

  &.mq-Geography {
    background: $BinderGeography;
  }

  &.mq-Economics {
    background: $BinderEconomics;
  }

  &.mq-California {
    background: $BinderCalifornia;
  }

  &.mq-Georgia {
    background: $BinderGeorgia;
  }

  &.mq-Elementary-1,
  &.mq-Elementary-2 {
    background: $BinderElementary;
  }

  &.mq-Louisiana {
   background: $BinderLouisiana1;  // gradient fall-back
    background: -moz-linear-gradient(180deg, $BinderLouisiana1 45%, $BinderLouisiana2 65%);
    background: -webkit-linear-gradient(180deg, $BinderLouisiana1 45%, $BinderLouisiana2 65%);
    background: linear-gradient(180deg, $BinderLouisiana1 45%, $BinderLouisiana2 65%);
  }

  .sab &:hover,
  .sab &:focus {
    border: 4px solid $DashBGreen;
  }

  .TOC & {
    padding: 0 5px;
    border: 0;
  }

  .uk-grid-margin {
    margin-top: 10px;
  }
}


@media (max-width: 767px) {
  .binderWrap {
    margin-left: auto;
    margin-right: auto;

  }

  .binder {
    width: 80vw;

    .libUnits-binder & {
      width: 100%;
    }
  }
}
.libUnits-binder .binder {
  margin-bottom: 15px;
}
